import 'bootstrap/dist/css/bootstrap.min.css';
import DeviceFocusPanel from './dashboard/device/devicePanel/DeviceFocusPanel';

import MainPage from "./main/MainPage";
function App() {
  return (
      <>
      <title>Multhingverse</title>
      <MainPage/>
 
      </>
  );
}

export default App;
