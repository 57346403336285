export const HOST = {
    userManagementApi: 
    {
        host:"http://multhingverse.com:9090",
        proxyPath:""
    },
     humanMicroserviceApi:
     {
         host:"multhingverse.com:9091",
         proxyPath:"",
         webSocketPath:"/websocket"
     }
}