import React from "react";

class Login extends React.Component
{
        constructor(props)
        {
            super(props);
        }render()
        {
            return(<div> Login </div>);
            }
        
}

export default Login;